import styled from '@emotion/styled';
import { theme } from '../../../../../../theme';

export const ListItem = styled.div`
  background-color: ${theme.colors.bgVariant};
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 60px;
  margin: 0 -16px 0 -16px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid ${theme.colors.borderSubtle};
  border-bottom: 1px solid ${theme.colors.borderSubtle};
  &:first-of-type {
    border-top: none;
  }
`;
