import styled from '@emotion/styled';

export const Container = styled.section`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-items: center;
  gap: 6px;
  margin: 12px;
`;
