import { groupBy, map, pipe, prop, toPairs, zipObj } from 'ramda';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectedFlowsContext, type ConnectedFlow } from '../../../../context/connected-flows';
import { useNavigationContext } from '../../../../context/navigation';
import { Container } from './container/Container';
import { Item } from './item/Item';
import { StyledAccordion } from './styles';

type GroupedFlows = { materialName: string; items?: ConnectedFlow[] }[];

export const ConnectedFlows: FC = () => {
  const { t } = useTranslation();
  const { selected } = useNavigationContext();
  const { connectedFlows } = useConnectedFlowsContext();

  if (selected?.type !== 'feature') {
    throw new Error('Invalid feature type');
  }

  const groupedFlows: GroupedFlows = pipe(
    groupBy<ConnectedFlow>(prop('materialName')),
    toPairs,
    map(zipObj(['materialName', 'items'])),
  )(connectedFlows);

  if (!groupedFlows.length) {
    return null;
  }

  return (
    <Container title={t('side-menu.zone.connected-flows-label')}>
      {groupedFlows
        ? groupedFlows.map((group) => (
            <StyledAccordion
              open={groupedFlows.length === 1}
              key={group.materialName}
              headerTitle={group.materialName}
              headerIcon="material"
            >
              {group.items?.map((flow) => (
                <Item
                  key={flow.flow.id}
                  flow={flow}
                  otherFeature={
                    flow.flow.fromId === selected.feature.properties.id
                      ? {
                          name: flow.toName,
                          type: 'to',
                        }
                      : { name: flow.fromName, type: 'from' }
                  }
                />
              ))}
            </StyledAccordion>
          ))
        : null}
    </Container>
  );
};
