import styled from '@emotion/styled';

export const Button = styled.button`
  border: none;
  background: transparent;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  border-radius: 5px;
  background: #fff;
`;
