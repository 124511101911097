import styled from '@emotion/styled';
import { Accordion } from '@volvo/vce-uikit';
import { theme } from '../../../../theme';

export const StyledAccordion = styled(Accordion)`
  summary {
    padding: 4px 0;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.textSubtle};
    &:hover {
      background-color: transparent;
    }
  }
  > div {
    padding: 8px 0 16px;
  }
`;
