import styled from '@emotion/styled';

export const Container = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Unit = styled.span`
  width: 55px;
  text-align: right;
  font-size: 12px;
`;
