import styled from '@emotion/styled';

export const Grid = styled.section`
  display: grid;
  row-gap: 24px;
  margin: 0;
`;

export const SelectedExternalReferenceWrapper = styled.div`
  margin-top: 5px;
`;
