import { type Polygon } from 'geojson';
import { useCallback, useEffect, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import MapBox, { GeolocateControl, NavigationControl, type MapRef } from 'react-map-gl';
import { useMutateSiteMapsCreate } from '../../../../gql-types/generated-types-super-graph';
import { ErrorMessage } from '../../../common/error/ErrorMessage';
import { drawBoundary, DrawBoundaryControl } from '../../draw';
import { MapContainer, mapStyles } from '../../styles';
import { Geocoder } from '../geocoder/Geocoder';
import { Ribbon } from './ribbon/Ribbon';
import { Container } from './styles';

type Props = { siteId: string; refetch: () => Promise<unknown> };

export const CreateBoundary: FC<Props> = ({ siteId, refetch }) => {
  const { t } = useTranslation();
  const map = useRef<MapRef>(null);
  const geolocateRef = useRef<mapboxgl.GeolocateControl>(null);

  const [boundary, setBoundary] = useState<Polygon>();

  useEffect(() => {
    map.current?.on('load', () => {
      geolocateRef.current?.trigger();
    });
  });

  const [createSite, { loading, error }] = useMutateSiteMapsCreate({
    onCompleted: () => {
      void refetch();
    },
  });

  const onMapLoad = useCallback(() => {
    if (!map.current) {
      return;
    }
    const drawMap = map.current.getMap();
    drawMap.dragRotate.disable();
    drawMap.touchZoomRotate.disableRotation();
  }, []);

  if (error) {
    return <ErrorMessage text={t('error')} description={error.message} />;
  }

  const handleCreateSite = useCallback(() => {
    if (boundary) {
      void createSite({
        variables: { input: { siteId, boundary: boundary.coordinates } },
      });
    }
  }, [boundary, createSite, siteId]);

  const onCancel = useCallback(() => {
    drawBoundary.deleteAll();
    setBoundary(undefined);
    drawBoundary.changeMode('siteBoundary');
  }, []);

  const height = `${window.innerHeight}px`;

  return (
    <Container>
      <MapContainer>
        <MapBox
          ref={map}
          onLoad={onMapLoad}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          mapboxAccessToken={import.meta.env.VITE_API_MAPBOX_TOKEN}
          style={mapStyles(height)}
          onRender={(event: { target: { resize: () => void } }) => {
            event.target.resize();
          }}
          trackResize
        >
          <Ribbon
            saveDisabled={loading}
            createSite={handleCreateSite}
            boundaryDrawn={!!boundary}
            onCancel={onCancel}
          />
          <DrawBoundaryControl
            position="top-left"
            onCreate={({ features }) => {
              setBoundary(features[0].geometry);
            }}
            onUpdate={({ features }) => {
              setBoundary(features[0].geometry);
            }}
          />
          <Geocoder position="top-left" mapboxAccessToken={import.meta.env.VITE_API_MAPBOX_TOKEN} />
          <GeolocateControl ref={geolocateRef} position="top-left" />
          <NavigationControl />
        </MapBox>
      </MapContainer>
    </Container>
  );
};
