import { useMemo, type FC } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatter } from '@volvo/vce-package-units';
import type { DataRow, SelectedMaterial, ZoneMaterialVariants } from '../../types';
import { Container, Unit } from './styles';

type Props = {
  min: number;
  max: number;
  data?: DataRow[];
  selectedMaterial: SelectedMaterial;
  zoneMaterialVariants: ZoneMaterialVariants;
  currentColor: string;
  unit: string;
};

export const Chart: FC<Props> = ({
  min,
  max,
  data,
  selectedMaterial,
  zoneMaterialVariants,
  currentColor,
  unit,
}) => {
  const yAxixWidth = useMemo(() => {
    const absMax = Math.max(Math.abs(min), Math.abs(max));
    return absMax > 99999999
      ? 95
      : absMax > 9999999
        ? 85
        : absMax > 999999
          ? 70
          : absMax > 99999
            ? 60
            : absMax > 9999
              ? 50
              : 40;
  }, [max, min]);

  return (
    <Container>
      <Unit>{unit}</Unit>
      <ResponsiveContainer width="99%">
        <AreaChart data={data}>
          <XAxis dataKey="label" tick={{ fontSize: 12 }} />
          <YAxis
            padding={{ bottom: 0, top: 30 }}
            domain={[min < 0 ? min : 0, max]}
            tick={{ fontSize: 12 }}
            tickCount={8}
            tickFormatter={(value) => formatter(value)}
            width={yAxixWidth}
          />
          <Tooltip
            wrapperStyle={{ zIndex: 1, maxWidth: '450px', overflow: 'hidden' }}
            contentStyle={{ whiteSpace: 'pre-wrap' }}
            labelStyle={{ fontSize: '14px' }}
            itemStyle={{ fontSize: '14px' }}
            position={{ x: 0, y: 275 }}
          />
          <CartesianGrid vertical={false} />
          {selectedMaterial.id === 'all' ? (
            zoneMaterialVariants.map((m) => (
              <Area
                dot={true}
                key={m.variant.id}
                connectNulls
                animationDuration={0}
                activeDot={{ r: 1 }}
                dataKey={`${m.variant.materialType.materialFamily.name} ${m.variant.materialType.name} ${m.variant.name}`}
                type="monotone"
                stroke={m.color}
                fill={m.color}
                fillOpacity={0.07}
              />
            ))
          ) : (
            <Area
              dot={true}
              connectNulls
              animationDuration={0}
              activeDot={{ r: 1 }}
              dataKey={selectedMaterial.name}
              type="monotone"
              stroke={currentColor}
              fill={currentColor}
              fillOpacity={0.07}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};
