import type { FC } from 'react';
import { ElementType } from '@volvo/vce-package-site-mapcommon';
import { SystemIcon } from '@volvo/vce-uikit';
import { useFlowsContext } from '../../../../context/flows';
import { useMapContext } from '../../../../context/map';
import { useNavigationContext } from '../../../../context/navigation';
import { poiTypeToIconMapper } from '../../../../helpers/poiTypeToIconMapper';

export const HeaderIcon: FC = () => {
  const { selected, mobileDrawerExpanded } = useNavigationContext();
  const { mapEdit } = useMapContext();
  const { editMode } = useFlowsContext();
  const margin = `${mobileDrawerExpanded ? '5px' : 0} 15px 0 0`;

  if (!selected) return null;

  const isNew = selected.type === 'feature' && selected.isNew;

  if (mapEdit || editMode) {
    return isNew ? null : <SystemIcon icon="edit" style={{ margin }} />;
  }

  if (
    selected.type === 'feature' &&
    selected.feature.properties.element_type === ElementType.POINT_OF_INTEREST
  ) {
    const poiType = selected.feature.properties.point_of_interest_type;

    const { icon } = poiTypeToIconMapper(poiType);

    if (!icon) return null;

    return <SystemIcon icon={icon} style={{ margin }} />;
  }

  if (
    selected.type === 'feature' &&
    selected.feature.properties.element_type === ElementType.ZONE
  ) {
    return <SystemIcon icon="positioning" style={{ margin }} />;
  }

  if (selected.type === 'flow') {
    return <SystemIcon icon="process-flow" style={{ margin }} />;
  }

  return null;
};
