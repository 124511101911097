import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const StyledInput = styled.input`
  width: 100%;
  padding: 16px;
  border: 0;
  background: #fff;
  color: #141414;
  resize: vertical;
  display: inline-block;
  font-size: 0.85em;
  height: 50px;
  border-radius: 4px;
  outline: 0;
  &:invalid {
    border: 1px solid red;
  }
`;

export const StyledFieldset = styled.fieldset`
  position: relative;
  border: 1px solid #a7a8a9;
  border-radius: 4px;
  &:active,
  :hover {
    border: #2b8ede solid 1px;
  }
`;

export const StyledLabel = styled.label<{ required: boolean }>`
  display: inline-block;
  color: #53565a;
  max-width: calc(100% - 96px);
  text-overflow: ellipsis;
  white-space: no-wrap;
  transition: color 240ms;
  transition-timing-function: cubig-bezier(0.45, 0, 0.125, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
  ${(props) =>
    props.required
      ? `
  &:after {
    padding-left: 3px;
    content: '*';
  }`
      : ''}
`;

export const StyledLegend = styled.legend<{ required: boolean; active: boolean }>`
  color: #53565a;
  max-width: calc(100% - 96px);
  text-overflow: ellipsis;
  white-space: no-wrap;
  transition: color 240ms;
  transition-timing-function: cubig-bezier(0.45, 0, 0.125, 1);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-left: 15px;
  padding: 0 5px;
  position: absolute;
  top: -10px;
  background: #fff;
  ${(props) =>
    props.required
      ? `
  &:after {
    padding-left: 3px;
    content: '*';
  }`
      : ''}
  ${(props) => (props.active ? 'font-size: 14px' : ' top: 14px; font-size: 16px')}
`;

export const HelperText = styled.div`
  display: block;
  text-align: start;
  margin: 8px 0 0 16px;
  color: #575757;

  ${css({ ...theme.typography.caption2 })}
  margin-left: 0;
`;
