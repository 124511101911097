import { DatePicker as DatePickerComponent } from 'antd';
import dayjs from 'dayjs';
import { type FC } from 'react';

import { Typography } from '@volvo/vce-uikit';

import { InputContainer } from './styles';

type Props = {
  value: Date | null;
  placeholder: Date;
  label: string;
  onChange: (date: Date | null) => void;
};

export const DatePicker: FC<Props> = ({ onChange, label, value, placeholder }) => (
  <section>
    <Typography variant="subtitle2">{label}</Typography>
    <InputContainer>
      <DatePickerComponent
        value={value ? dayjs(value) : null}
        onChange={(date) => {
          onChange(date ? date.toDate() : null);
        }}
        variant="borderless"
        style={{ width: '100%', height: '50px', padding: '16px' }}
        format="YYYY-MM-DD HH:mm"
        showTime={{ format: 'HH:mm', defaultValue: dayjs().startOf('day') }}
        placeholder={dayjs(placeholder).format('YYYY-MM-DD HH:mm')}
        disabledDate={(current) => current > dayjs().endOf('day')}
        hideDisabledOptions
        changeOnBlur
      />
    </InputContainer>
  </section>
);
