import { DateTime } from 'luxon';
import { type ChangeEventHandler, type FC } from 'react';
import { Typography } from '@volvo/vce-uikit';
import { useSiteSettingsContext } from '../../../../../../context/site-settings/SiteSettingsContext';
import { type FragmentGeographyPhotos } from '../../../../../../gql-types/generated-types-super-graph';
import { NoWrapEllipsisTypography } from '../../../../../common/typography/no-wrap-ellipsis/NoWrapEllipsis';
import { DeleteIcon } from './delete';
import { Row } from './styles';

type Props = {
  item: FragmentGeographyPhotos;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onDeleted: (id: string) => void;
  disabled: boolean;
};

export const ListItem: FC<Props> = ({
  item: { id, name, createdAt, isActive },
  onChange,
  onDeleted,
  disabled,
}) => {
  const { editMode } = useSiteSettingsContext();

  return (
    <Row>
      <input
        type="checkbox"
        checked={isActive}
        onChange={onChange}
        disabled={disabled || !editMode}
        style={{ marginRight: '15px' }}
      />
      <NoWrapEllipsisTypography color="textDisabled" variant="body" text={name ?? ''} />
      <Typography color="text" variant="subtitle1">
        {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)}
      </Typography>
      {editMode && <DeleteIcon id={id} onDelete={onDeleted} />}
    </Row>
  );
};
