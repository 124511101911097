import { capitalize } from 'lodash';
import { forwardRef, memo, useRef, useState, type ForwardRefRenderFunction } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon, Tooltip, Typography, type SystemIconId } from '@volvo/vce-uikit';
import type { GuestDevice } from '../../../../../../../context/machine-locations';
import { theme } from '../../../../../../../theme';
import { ListItem, Titles } from '../../styles';

type Props = {
  guest: GuestDevice;
  onClick: (machine: GuestDevice) => void;
  disabled: boolean;
  selected: boolean;
  online: boolean;
  icon?: SystemIconId;
};

const GuestComponent: ForwardRefRenderFunction<HTMLLIElement, Props> = (
  { guest, onClick, disabled, online, selected, icon },
  reference,
) => {
  const { t } = useTranslation();

  const [open, setOpens] = useState(false);
  const ref = useRef<HTMLElement>(null);

  const handleOnClick = () => {
    if (disabled) return;
    onClick(guest);
  };

  return (
    <ListItem onClick={handleOnClick} disabled={disabled} selected={selected} ref={reference}>
      <section ref={ref}>
        {icon && (
          <SystemIcon
            style={{ color: online ? theme.colors.iconOnSuccess : theme.colors.textDisabled }}
            icon={icon}
            size={48}
          />
        )}
        <Tooltip open={open} setOpen={setOpens} reference={ref.current}>
          {capitalize(guest.machineType)}
        </Tooltip>
      </section>
      <Titles>
        <Typography variant="subtitle1" color="text" style={{ wordBreak: 'break-word' }}>
          {t('guest')}
        </Typography>
        <Typography variant="button" color="textSubtle" style={{ wordBreak: 'break-word' }}>
          {`${t('driver')}: ${guest.machineName}`}
        </Typography>
      </Titles>
    </ListItem>
  );
};

export const Guest = memo(forwardRef(GuestComponent));
