import styled from '@emotion/styled';
import { Popup } from 'react-map-gl';

export const CustomPopup = styled(Popup)`
  z-index: 5;
  div {
    display: grid;
    justify-items: center;
    row-gap: 4px;
    pointer-events: auto;
  }
  .mapboxgl-popup-content {
    border: none;
    background-color: transparent;
    box-shadow: none;
    pointer-events: none;
  }
  .mapboxgl-popup-tip {
    border: none;
  }
`;
