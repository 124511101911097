import { type FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography } from '@volvo/vce-uikit';
import { ButtonsContainer, TextContainer } from './styles';

interface ConfirmDialogProps {
  title: string;
  text?: string;
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  text,
  visible,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation('common');
  const { t: sitemapT } = useTranslation();

  return createPortal(
    <Dialog open={visible} onClose={onClose}>
      <TextContainer>
        <Typography style={{ marginBottom: '0.75rem' }} variant="heading4">
          {title}
        </Typography>
        {text && (
          <Typography style={{ marginBottom: '1rem' }} variant="body">
            {text}
          </Typography>
        )}
      </TextContainer>
      <ButtonsContainer>
        <Button variant="secondary" onClick={onCancel} style={{ marginRight: '1rem' }}>
          {sitemapT('cancel')}
        </Button>
        <Button onClick={onConfirm} variant="primary">
          {t('Yes')}
        </Button>
      </ButtonsContainer>
    </Dialog>,
    document.body,
  );
};
