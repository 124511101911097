import styled from '@emotion/styled';

export const Row = styled.section`
  display: grid;
  grid-template-columns: 24px auto;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;
