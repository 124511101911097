import styled from '@emotion/styled';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type KeyboardEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '@volvo/vce-uikit';
import { SearchResult, SearchResultWrapper } from './styles';

interface SearchAndSelectProps<T> {
  items: T[];
  renderItem: (item: T) => JSX.Element;
  onChange: (value: string) => void;
  onSelect: (item: T) => void;
}

export const NoResult = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.map.layout.padding} ${(props) => props.theme.map.layout.padding};
`;

export const SearchAndSelect = <T extends Record<string, unknown>>({
  items,
  renderItem,
  onChange,
  onSelect,
}: SearchAndSelectProps<T>) => {
  const { t } = useTranslation();

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [displayText, setDisplayText] = useState<string>('');

  const resultContainer = useRef<HTMLLIElement>(null);

  const handleSelection = (selectedItem?: T) => {
    if (selectedItem) {
      onSelect(selectedItem);
    }
    resetSearchComplete();
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
    setDisplayText('');
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    let nextIndexCount = 0;
    const nativeEvent = Object.values(e.detail)[1];
    const identifiedKey = e.key === 'Unidentified' ? nativeEvent.key : e.key;

    if (identifiedKey === 'ArrowDown') {
      nextIndexCount = (focusedIndex + 1) % items.length;
    }

    if (identifiedKey === 'ArrowUp') {
      nextIndexCount = (focusedIndex + items.length - 1) % items.length;
    }

    if (identifiedKey === 'Escape') {
      resetSearchComplete();
    }

    if (identifiedKey === 'Enter') {
      e.preventDefault();
      handleSelection(items[focusedIndex]);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleChange = (val: string) => {
    onChange(val);
  };

  useEffect(() => {
    if (!resultContainer.current?.scrollIntoView) return;

    resultContainer.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedIndex]);

  useEffect(() => {
    setShowResults((prev) => {
      if (items.length > 0 && !prev) return true;
      return prev;
    });
  }, [items]);

  return (
    <div
      style={{ position: 'relative' }}
      tabIndex={1}
      onBlur={() => {
        setShowResults(false);
      }}
      onKeyDown={handleKeyDown}
    >
      <SearchField
        icon="search"
        placeholder={t('details-panel.zone.seach-variant-placeholder')}
        size="small"
        value={displayText}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setDisplayText(e.target.value);
          handleChange(e.target.value);
        }}
        onClick={(e) => {
          setShowResults(true);
          if ((e.target as HTMLInputElement).value === '') {
            onChange('');
            setDisplayText('');
          }
        }}
      />
      {showResults && (
        <SearchResultWrapper>
          {items.length === 0 && <NoResult>{t('details-panel.zone.no-material-found')}</NoResult>}
          {items.length > 0 &&
            items.map((i, index) => (
              <SearchResult
                key={index}
                onMouseDown={() => {
                  handleSelection(i);
                }}
                ref={index === focusedIndex ? resultContainer : null}
                style={{
                  backgroundColor: index === focusedIndex ? 'rgba(0, 0, 0, 0.1)' : undefined,
                }}
                data-testid="search-result"
              >
                {renderItem(i)}
              </SearchResult>
            ))}
        </SearchResultWrapper>
      )}
    </div>
  );
};
