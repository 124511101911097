import { type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@volvo/vce-uikit';

import { ButtonWrapper } from '../styles';

type Props = {
  onSave: () => void;
  onCancel: () => void;
  DeleteButton?: ReactNode;
  disabled?: boolean;
  cancelDisabled?: boolean;
};

export const EditModeButtons: FC<Props> = ({
  onSave,
  onCancel,
  DeleteButton = null,
  disabled = false,
  cancelDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonWrapper>
      {DeleteButton}
      {/* margin is a workaround for the DeleteButton Dialog taking up arbitrary horizontal space */}
      <Button variant="secondary" onClick={onCancel} disabled={cancelDisabled}>
        {t('cancel')}
      </Button>
      <Button onClick={onSave} disabled={disabled} variant="primary">
        {t('details-panel.save-and-close-button-text')}
      </Button>
    </ButtonWrapper>
  );
};
