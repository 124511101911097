import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const SearchResultWrapper = styled.ul`
  margin: 5px 5px 0 5px;
  z-index: 5;
  position: absolute;
  width: 98%;
  max-height: 450px;
  overflow-y: auto;
  background-color: ${theme.colors.bg};
  box-shadow: ${theme.map.design.boxShadow.volvoCard};
`;

export const SearchResult = styled.li`
  padding: 0 5px 0 5px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
