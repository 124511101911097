import styled from '@emotion/styled';

export const Dot = styled.span<{ size?: number }>`
  display: inline-block;
  width: ${(props) => props.size ?? '15'}px;
  height: ${(props) => props.size ?? '15'}px;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.map.layout.margin};
  background-color: ${(props) => props.color};
`;
