import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import type { Item } from '../types';

type Flow = Item & {
  assignedMachines?: { id: string; name: string }[];
};

export type Props = {
  item: Flow;
};

const Description = styled.div`
  color: #888b8d;
  font-size: 16px;
  word-break: break-word;
`;

export const FlowItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Typography variant="caption2" style={{ textTransform: 'inherit' }}>
        {t('side-menu.flows.assigned-machines')}:{' '}
        {!item?.assignedMachines?.length
          ? '-'
          : item.assignedMachines.map((m) => m.name).join(', ')}
      </Typography>
      <div style={{ textTransform: 'inherit' }}>
        <Typography color="text" variant="body" style={{ wordBreak: 'break-word' }}>
          {item.name}
        </Typography>
      </div>
      {item.description && <Description>{item.description}</Description>}
    </section>
  );
};
