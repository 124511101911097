import { type FC } from 'react';

import { SystemIcon } from '@volvo/vce-uikit';

import { useNavigationContext } from '../../../context/navigation';
import { Container, Content } from './styles';

export const Crevron: FC = () => {
  const { leftPanelOpen, setLeftPanelOpen, isMobile, isTabletPortrait } = useNavigationContext();

  if (isMobile || isTabletPortrait) return null;

  return (
    <Container
      style={{ zIndex: 50 }}
      open={!leftPanelOpen}
      onClick={() => {
        setLeftPanelOpen((prev) => !prev);
      }}
    >
      <Content>
        <SystemIcon
          filled={true}
          icon={leftPanelOpen ? 'chevron-left-last' : 'chevron-right-last'}
          size={16}
        />
      </Content>
    </Container>
  );
};
