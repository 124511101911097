import { type FC } from 'react';
import { ErrorBoundaryWrapped } from '../../common/error/error-boundary-wrapped/ErrorBoundaryWrapped';
import { LeftPanel } from '../../left-panel/LeftPanel';
import { RightPanel } from '../../right-panel/RightPanel';
import { TopMenu } from '../../top-menu/TopMenu';
import { WebsocketUnavailableBanner } from '../../websocket-unavailable-banner/WebsocketUnavailableBanner';
import { Map } from '../Map';
import { MapContainer, Wrapper } from './styles';

export const MapWrapper: FC = () => (
  <Wrapper>
    <ErrorBoundaryWrapped component={<TopMenu />} />
    <ErrorBoundaryWrapped component={<WebsocketUnavailableBanner />} />
    <MapContainer>
      <ErrorBoundaryWrapped component={<LeftPanel />} />
      <ErrorBoundaryWrapped component={<Map />} />
      <ErrorBoundaryWrapped component={<RightPanel />} />
    </MapContainer>
  </Wrapper>
);
