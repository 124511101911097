import styled from '@emotion/styled';

export const ButtonsContainer = styled.div`
  justify-content: end;
  display: flex;
  margin-top: 2rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
