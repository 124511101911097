import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../../../../context/navigation';
import { useSiteContext } from '../../../../../../context/site';
import type { MachineType } from '../../../../../../context/zone/types';
import { useZoneContext } from '../../../../../../context/zone/ZoneContext';
import { getMachineAppearance } from '../../../../../../helpers';
import { machineTypeZoneRoles } from '../../../../../../types';
import { ErrorMessage } from '../../../../../common/error/ErrorMessage';
import { WrapperVertical } from '../../../../../common/wrappers/Wrappers';
import { Body, Title } from '../../../../typography/Typography';
import { Container } from '../styles';
import { Permission } from './styles';

export const Permissions: FC = () => {
  const { site } = useSiteContext();
  const { selected } = useNavigationContext();
  const { roleAssignments } = useZoneContext();
  const { t } = useTranslation();

  if (selected?.type !== 'feature') {
    throw new Error('Invalid feature type');
  }

  const roles = useMemo(() => {
    const data: Record<string, MachineType[]> = {};
    const machineTypesWithPermissions: MachineType[] = [];
    machineTypeZoneRoles.forEach((zoneRole) => {
      const machineTypes = roleAssignments[zoneRole];

      if (machineTypes?.length > 0) {
        machineTypes.forEach((machineType) => {
          if (!machineTypesWithPermissions.includes(machineType)) {
            machineTypesWithPermissions.push(machineType);
          }
        });

        data[zoneRole] = machineTypes.sort((a, b) => a.machineType.localeCompare(b.machineType));
      }
    });

    const restrictedMachineTypes = site.allowedMachineTypes.filter(
      (machineType) => !machineTypesWithPermissions.includes(machineType),
    );

    if (restrictedMachineTypes.length > 0) {
      const existing = data.restricted ?? [];
      data.restricted = [...existing, ...restrictedMachineTypes].sort((a, b) =>
        a.machineType.localeCompare(b.machineType),
      );
    }

    return data;
  }, [roleAssignments, site.allowedMachineTypes]);

  return (
    <WrapperVertical>
      {Object.keys(roles).length === 0 ? (
        <ErrorMessage text={t('details-panel.zone.no-machine-types-configures')} />
      ) : (
        Object.keys(roles).map((role) => (
          <Container key={role}>
            <Title text={t(`details-panel.zone.zone-roles.${role}`)} />
            {roles[role].map((machineType) => (
              <Permission key={machineType.id}>
                <SystemIcon
                  icon={getMachineAppearance(machineType.machineType).icon}
                  style={{ marginRight: '5px' }}
                />
                <Body key={machineType.id} text={t(`machine-types.${machineType.machineType}`)} />
              </Permission>
            ))}
          </Container>
        ))
      )}
    </WrapperVertical>
  );
};
