import styled from '@emotion/styled';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon, Typography } from '@volvo/vce-uikit';
import type { ExternalReference } from '../../../../../../types';

const SelectedExternalReference = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.map.layout.verticalPadding};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderSubtle};
`;

type Props = {
  externalReference: ExternalReference;
  onClick: () => void;
};

export const Row: FC<Props> = ({ externalReference, onClick }) => {
  const { t, i18n } = useTranslation();

  const friendlyName = `${
    i18n.exists(`details-panel.flow.external-reference.types.${externalReference.type}`)
      ? t(`details-panel.flow.external-reference.types.${externalReference.type}`)
      : externalReference.type
  } - ${externalReference.value}`;

  return (
    <SelectedExternalReference>
      <Typography>{friendlyName}</Typography>
      <SystemIcon style={{ cursor: 'pointer' }} icon="remove" onClick={onClick} />
    </SelectedExternalReference>
  );
};
