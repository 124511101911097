import { type FC, type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { SystemIcon, type SystemIconId } from '@volvo/vce-uikit';

import { Button, ButtonWrapper, CollapsibleDiv } from './styles';

interface CollapsibleProps {
  open?: boolean;
  vceIconName: SystemIconId;
}

export const Collapsible: FC<PropsWithChildren<CollapsibleProps>> = ({
  open,
  vceIconName,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(isOpen ? ref.current?.getBoundingClientRect().height : 0);
  }, [isOpen]);

  const handleFilterOpening = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <ButtonWrapper>
      <Button onClick={handleFilterOpening}>
        <SystemIcon filled={true} icon={vceIconName} size={16} />
      </Button>
      <CollapsibleDiv style={{ height }}>
        <div ref={ref}>{isOpen && children}</div>
      </CollapsibleDiv>
    </ButtonWrapper>
  );
};
