import styled from '@emotion/styled';
import { theme } from '../../../../../../../../theme';

export const InputContainer = styled.div`
  margin-top: 4px;
  border: 1px solid #9d9d9d;
  border-radius: 4px;
  padding: 1px;
`;

export const Header = styled.section`
  border-bottom: 1px solid ${theme.colors.borderSubtle};
  margin: -6px -24px 0;
  padding: 0 24px 12px;
`;

export const Footer = styled.section`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: end;
  border-top: 1px solid ${theme.colors.borderSubtle};
  margin: 0 -24px -6px;
  padding: 12px 24px 0;
`;
