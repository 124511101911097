import styled from '@emotion/styled';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@volvo/vce-uikit';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.map.layout.padding} 0;
`;

type Props = { onCancel: () => void };

export const Cancel: FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Button variant="secondary" onClick={onCancel}>
        {t('cancel')}
      </Button>
    </Wrapper>
  );
};
