import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ContentSwitcher, ContentSwitcherOption } from '@volvo/vce-uikit';
import { drawBoundary } from '../../../../draw';
import { SwitchWrapper } from '../../../ribbons/edit-zone/styles';
import { Container } from './styles';

type Modes = { move: string; edit: string };

type Props = {
  saveDisabled: boolean;
  createSite: () => void;
  onCancel: () => void;
  lengthUnit: string;
};

export const BoundaryDrawn: FC<Props> = ({ createSite, saveDisabled, lengthUnit, onCancel }) => {
  const { t } = useTranslation();

  const switchOptions: Modes = {
    move: t('initial-setup.edit-mode-move'),
    edit: t('initial-setup.edit-mode-edit'),
  };

  const [selectedMode, setSelectedMode] = useState<keyof Modes>('move');

  const onChangeMode = (mode: keyof Modes) => {
    const featureId = drawBoundary.getAll()?.features[0]?.id;
    setSelectedMode(mode);
    if (mode === 'move') {
      // @ts-expect-error the simple_select mode has been overridden by us
      drawBoundary.changeMode('simple_select', {
        featureIds: [featureId],
        mode: 'EDIT_BOUNDARY',
        siteBoundaryId: featureId,
      });
    }
    if (mode === 'edit') {
      // @ts-expect-error the direct_select mode has been overridden by us
      drawBoundary.changeMode('direct_select', {
        featureId,
        siteBoundaryId: featureId,
        lengthUnit,
      });
    }
  };

  return (
    <Container>
      <SwitchWrapper>
        <ContentSwitcher>
          {Object.entries(switchOptions).map(([mode, translation]) => (
            <ContentSwitcherOption
              key={mode}
              onClick={() => {
                if (mode === selectedMode) {
                  return;
                }
                onChangeMode(mode as keyof Modes);
              }}
              active={mode === selectedMode}
              children={translation}
            />
          ))}
        </ContentSwitcher>
      </SwitchWrapper>
      <section style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '8px' }}>
        <Button size="large" variant="primary" disabled={saveDisabled} onClick={createSite}>
          {t('save')}
        </Button>
        <Button size="large" variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </section>
    </Container>
  );
};
