import styled from '@emotion/styled';
import { type FC, type MouseEventHandler } from 'react';

import { SystemIcon, type SystemIconId } from '@volvo/vce-uikit';

const Button = styled.button`
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
`;

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: SystemIconId;
};

export const ClickableIcon: FC<Props> = ({ onClick, icon }) => (
  <Button onClick={onClick}>
    <SystemIcon icon={icon} size={24} />
  </Button>
);
