import styled from '@emotion/styled';
import { type FC } from 'react';

import { HorizontalMenuItem } from '@volvo/vce-uikit';

interface TopMenuItemProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

const Item = styled.span`
  cursor: pointer;
`;

const DisabledItem = styled.span`
  cursor: not-allowed;
`;

export const TopMenuItem: FC<TopMenuItemProps> = ({ onClick, text, disabled }) => (
  <HorizontalMenuItem>
    {disabled ? <DisabledItem>{text}</DisabledItem> : <Item onClick={onClick}>{text}</Item>}
  </HorizontalMenuItem>
);
