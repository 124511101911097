import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../context/navigation';
import { ErrorBoundaryWrapped } from '../../common/error/error-boundary-wrapped/ErrorBoundaryWrapped';
import type { TabKeys } from '../tabs/Tabs';
import { ListItem, SearchContainer, StyledAccordion } from './styles';
import type { Item } from './types';

export interface ListItemsProps {
  items: Item[];
  groupedItems?: { id: string; name: string; items: Item[] }[];
  onClick: (item: Item) => void;
  renderItem: (item: Item) => ReactElement;
  disabled?: boolean;
  openTab?: keyof typeof TabKeys;
}

function ListItemsComponent({
  items,
  openTab,
  onClick,
  renderItem,
  groupedItems,
  disabled = false,
}: PropsWithChildren<ListItemsProps>) {
  const { t } = useTranslation();
  const { selected } = useNavigationContext();
  const [searchTerm, setSearchTerm] = useState('');

  const ref = useRef<HTMLLIElement>(null);

  const search = useCallback(
    (value: string): Item[] => {
      if (!items) {
        return [];
      }

      if (!value?.trim()) {
        return items;
      }

      return items.filter(
        (item) =>
          item.searchables?.filter((s) => s.toLowerCase().includes(value.toLowerCase())).length,
      );
    },
    [items],
  );

  const searchResults = useMemo(
    () => (searchTerm ? search(searchTerm) : items),
    [items, search, searchTerm],
  );

  const selectedId = useMemo(() => {
    if (selected?.type === 'feature') {
      return selected.feature.id;
    }

    if (selected?.type === 'flow' && selected.flow) {
      return selected.flow.id;
    }

    return null;
  }, [selected]);

  const handleChange = (val: string) => {
    if (!items) {
      return;
    }
    setSearchTerm(val);
  };

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref.current]);

  useEffect(() => {
    setSearchTerm('');
  }, [openTab]);

  return (
    <ErrorBoundaryWrapped>
      <SearchContainer>
        <TextField
          placeholder={t('search')}
          floatingLabel={false}
          size="small"
          value={searchTerm}
          icon="search"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </SearchContainer>
      <ul>
        {groupedItems
          ? groupedItems.map((group) => (
              <StyledAccordion
                open
                key={group.id}
                headerTitle={`${group.name} (${group.items.length})`}
              >
                {group.items
                  .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                  .map((item) =>
                    searchResults.find((s) => s.id === item.id) ? (
                      <ListItem
                        key={item.id}
                        onClick={() => {
                          if (disabled) return;
                          onClick(item);
                        }}
                        selected={selectedId === item.id}
                        disabled={disabled}
                        ref={selectedId === item.id ? ref : null}
                      >
                        {renderItem(item)}
                      </ListItem>
                    ) : null,
                  )}
              </StyledAccordion>
            ))
          : searchResults
              ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
              .map((item, index) => (
                <ListItem
                  key={index}
                  onClick={() => {
                    if (disabled) return;
                    onClick(item);
                  }}
                  selected={selectedId === item.id}
                  disabled={disabled}
                  ref={selectedId === item.id ? ref : null}
                >
                  {renderItem(item)}
                </ListItem>
              ))}
      </ul>
    </ErrorBoundaryWrapped>
  );
}

export const ListItems = memo(ListItemsComponent) as typeof ListItemsComponent;
