import styled from '@emotion/styled';
import { Accordion } from '@volvo/vce-uikit';
import { theme } from '../../../theme';

export const ListItem = styled.li<{ disabled?: boolean; selected?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin: 0;
  padding: 16px;
  min-height: 64px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${theme.colors.borderSubtle};
  background-color: ${(props) => (props.selected ? theme.colors.bgPressed : 'transparent')};
  &:hover {
    background-color: ${theme.colors.bgHover};
  }
  &:active {
    background-color: ${theme.colors.bgPressed};
  }
`;

export const SearchContainer = styled.div`
  padding: 0 1rem 16px 1rem;
  border-bottom: 1px solid ${theme.colors.borderSubtle};
`;

export const StyledAccordion = styled(Accordion)`
  summary {
    padding: 8px 16px;
    font-size: 14px;
    color: ${theme.colors.textSubtle};
    background-color: ${theme.colors.bgPressed};
  }

  div {
    padding: 0;
    li:last-child {
      border-bottom: none;
    }
  }
`;
