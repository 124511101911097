import { type FC } from 'react';
import { SystemIcon } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../context/navigation';
import { Container } from './styles';

export const Close: FC = () => {
  const { setLeftPanelOpen, isMobile, isTabletPortrait } = useNavigationContext();

  if (!isMobile && !isTabletPortrait) return null;

  return (
    <Container>
      <SystemIcon
        onClick={() => {
          setLeftPanelOpen(false);
        }}
        icon="close"
      />
    </Container>
  );
};
