import styled from '@emotion/styled';
import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { SystemIcon, Typography } from '@volvo/vce-uikit';
import {
  bgInverse,
  iconOnInverse,
} from '@volvo/vce-uikit-tokens/dist/web/volvo/colors/semantic-light';
import { useMapContext } from '../../context/map';
import { useWebSocketContext } from '../../context/websocket';

const WebsocketUnavailableBannerWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 16px 32px;
  align-items: center;
  background-color: ${bgInverse};
`;

export const WebsocketUnavailableBanner: FC = () => {
  const { t } = useTranslation();
  const { socketReady } = useWebSocketContext();
  const { mapEdit } = useMapContext();
  const { default: map } = useMap();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(!socketReady && !mapEdit);
    map?.triggerRepaint();
  }, [mapEdit, socketReady]);

  if (!showBanner) {
    return null;
  }

  return (
    <WebsocketUnavailableBannerWrapper>
      <SystemIcon
        icon={'wireless-communication-off'}
        size={32}
        style={{ color: iconOnInverse, marginRight: 16 }}
        data-testid="connectedStatus"
      />
      <Typography variant="caption1" color="textOnInverse">
        {t('websocket-status.websocket-unavailable-text')}
      </Typography>
    </WebsocketUnavailableBannerWrapper>
  );
};
