import { explode, featureCollection, pointsWithinPolygon } from '@turf/turf';
import { type LineString, type Point, type Polygon } from 'geojson';

import type { CustomFeature, CustomFeatureBoundary } from '../types';

type Props = { features: CustomFeature[]; siteBoundary: CustomFeatureBoundary };

export const featuresAreInsideBoundary = ({ features, siteBoundary }: Props): boolean => {
  const points = explode(featureCollection<Polygon | LineString | Point>(features));
  const pointsInisdeSiteBoundary = pointsWithinPolygon(points, siteBoundary);

  return pointsInisdeSiteBoundary.features.length === points.features.length;
};
