import { DateTime, Interval } from 'luxon';
import { groupBy, path, pipe } from 'ramda';
import { useCallback, useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementType, ZoneType } from '@volvo/vce-package-site-mapcommon';
import { formattedUnit, type Units } from '@volvo/vce-package-units';
import { Button, ContextMenu, ContextMenuItem, ReferenceType } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../../../../../context/navigation';
import { useSiteContext } from '../../../../../../../context/site';
import { useSiteConfigContext } from '../../../../../../../context/site-config/SiteConfigContext';
import {
  useQueryMaterialBalanceByZoneId,
  type FragmentMaterialBalance,
  type FragmentMaterialVariantWithMaterialType,
  type QueryMaterialBalanceByZoneId,
} from '../../../../../../../gql-types/generated-types-super-graph';
import { isNotNull } from '../../../../../../../helpers';
import { convertMeasurements } from '../../../../../../../helpers/convert/convert';
import type { ConvertUnits } from '../../../../../../../helpers/convert/types';
import { EmptyState } from '../../../../../../common/empty-state/EmptyState';
import { CenteredSpinner } from '../../../../../../common/loading/CenteredSpinner';
import { Title } from '../../../../../typography/Typography';
import { Container } from '../../row/styles';
import {
  daysOptions,
  type DataRow,
  type DaysOption,
  type SelectedMaterial,
  type ZoneMaterialVariants,
} from '../types';
import { Chart } from './chart/Chart';
import { MaterialSelector } from './material-selector/MaterialSelector';
import { SetBaseline } from './set-baseline/SetBaseline';
import { ButtonContainer, TitleContainer } from './styles';

const ChartColors = [
  '#4C75BF',
  '#78B833',
  '#50a294',
  '#346e85',
  '#FFB54D',
  '#50a294',
  '#9450a2',
  '#a29450',
];

type Props = {
  zoneId: string;
};

export const getMaterialName = (material: FragmentMaterialVariantWithMaterialType) =>
  `${material.materialType.materialFamily.name} ${material.materialType.name} ${material.name}`;

export const MaterialBalance: FC<Props> = ({ zoneId }) => {
  const { selected } = useNavigationContext();
  const { materials } = useSiteContext();

  const materialVariants = materials.flatMap((family) =>
    family.materialTypes.flatMap((type) => type.materialVariants),
  );

  if (
    (selected?.type === 'feature' &&
      selected.feature.properties.element_type === ElementType.ZONE &&
      selected?.feature.properties.zone_type !== ZoneType.MATERIAL_ZONE) ||
    !materialVariants.length
  ) {
    return null;
  }

  return <MaterialBalanceContent zoneId={zoneId} materialVariants={materialVariants} />;
};

type PropsInner = {
  materialVariants: FragmentMaterialVariantWithMaterialType[];
} & Props;

const MaterialBalanceContent: FC<PropsInner> = ({ zoneId, materialVariants }) => {
  const { t } = useTranslation();
  const { units, convertToBestUnit } = useSiteConfigContext();

  const [zoneMaterialVariants, setZoneMaterialVariants] = useState<ZoneMaterialVariants>([]);
  const [data, setData] = useState<DataRow[]>();
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [unit, setUnit] = useState<ConvertUnits>(units.mass.unit);
  const [selectedMaterial, setSelectedMaterial] = useState<SelectedMaterial>();

  const [daysOpen, setDaysOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState<DaysOption>(7);
  const [refDateElement, setRefDateElement] = useState<HTMLElement | null>(null);
  const [currentColor, setCurrentColor] = useState(ChartColors[0]);

  const fromDate = DateTime.local()
    .startOf('day')
    .minus({
      days: selectedDays - 1,
    });

  const toDate = DateTime.local().endOf('day');
  const interval = Interval.fromDateTimes(fromDate, toDate.startOf('day'))
    .splitBy({ day: 1 })
    .map((d) => d.start)
    .concat(toDate)
    .filter(isNotNull);

  const { refetch, loading } = useQueryMaterialBalanceByZoneId({
    variables: { zoneId, fromDate, toDate },
    pollInterval: Number(import.meta.env.VITE_POLL_INTERVAL),
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.zoneMaterialBalance.byZoneId) {
        setData(formatData(interval, data.zoneMaterialBalance.byZoneId));
      } else {
        setData([]);
      }
    },
  });

  const formatData = useCallback(
    (
      interval: DateTime[],
      data: QueryMaterialBalanceByZoneId['zoneMaterialBalance']['byZoneId'],
    ) => {
      const zoneMat: { color: string; variant: FragmentMaterialVariantWithMaterialType }[] = [];
      const formatted = pipe(
        groupBy(
          (balance: FragmentMaterialBalance) =>
            DateTime.fromISO(balance.createdAt).toSQLDate() || '',
        ),
        (g) =>
          Object.entries(g)
            .map(([date, value]) => {
              if (!value) {
                return null;
              }

              const grouped = groupBy<FragmentMaterialBalance, string>(
                (balance: FragmentMaterialBalance) =>
                  path<string>(['zoneMaterialVariant', 'materialVariantId'], balance) || '',
              )(value);

              return Object.values(grouped)
                .map((v) => {
                  if (!v) {
                    return null;
                  }

                  const [lastEntryForDate] = v.sort((a, b) =>
                    a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0,
                  );

                  const newVariant = materialVariants.find(
                    (x) => x.id === lastEntryForDate.zoneMaterialVariant.materialVariantId,
                  );

                  if (!newVariant) {
                    console.error(
                      'Could not find balance material id among site materials',
                      lastEntryForDate.zoneMaterialVariant.materialVariantId,
                    );
                    return null;
                  }

                  if (!zoneMat.find((x) => x.variant.id === newVariant.id)) {
                    zoneMat.push({ color: ChartColors[zoneMat.length], variant: newVariant });
                  }

                  if (
                    selectedMaterial?.id !== 'all' &&
                    lastEntryForDate.zoneMaterialVariant.materialVariantId !== selectedMaterial?.id
                  ) {
                    return null;
                  }

                  return {
                    amount: lastEntryForDate.amount,
                    date,
                    materialVariantId: lastEntryForDate.zoneMaterialVariant.materialVariantId,
                  };
                })
                .filter(isNotNull);
            })
            .filter(isNotNull),
      )(data).flat();

      setZoneMaterialVariants(zoneMat);

      const minValue = Math.min(...formatted.map((x) => x.amount));
      const maxValue = Math.max(...formatted.map((x) => x.amount));

      let [valueClosestToZero] = formatted
        .map((x) => x.amount)
        .filter((x) => x !== 0)
        .sort((a, b) => Math.abs(a) - Math.abs(b));

      if (!valueClosestToZero) valueClosestToZero = 1;

      const bestUnit = convertToBestUnit(valueClosestToZero, 'mass');
      if (!bestUnit) {
        return;
      }

      setUnit(formattedUnit(bestUnit.unit as Units) as ConvertUnits);
      setMinValue(
        Number(convertMeasurements(minValue).from(units.mass.base).to(bestUnit.unit).toFixed()),
      );
      setMaxValue(
        Number(convertMeasurements(maxValue).from(units.mass.base).to(bestUnit.unit).toFixed()),
      );

      const values: DataRow[] = [];

      interval.forEach((date) => {
        const materials = formatted
          .filter((i) => i.date === date.toSQLDate())
          .map(({ amount, materialVariantId }) => {
            const material = materialVariants.find(({ id }) => id === materialVariantId);
            if (!material) {
              return null;
            }

            return {
              [getMaterialName(material)]: convertMeasurements(amount)
                .from(units.mass.base)
                .to(bestUnit.unit)
                .toFixed(),
            };
          });

        values.push({ date, label: date.toFormat('dd/MM'), ...Object.assign({}, ...materials) });
      });

      return values;
    },
    [convertToBestUnit, materialVariants, selectedMaterial, units.mass.base],
  );

  useEffect(() => {
    if (!selectedMaterial && zoneMaterialVariants.length) {
      if (zoneMaterialVariants.length > 1 && zoneMaterialVariants.length < 9) {
        setSelectedMaterial({ id: 'all', name: 'all' });
      } else {
        setSelectedMaterial({
          id: zoneMaterialVariants[0].variant.id,
          name: getMaterialName(zoneMaterialVariants[0].variant),
        });
      }
    }
  }, [selectedMaterial, zoneMaterialVariants]);

  useEffect(() => {
    setZoneMaterialVariants([]);
    setSelectedMaterial(undefined);
  }, [zoneId]);

  const onMaterialChange = useCallback(
    (material: SelectedMaterial) => {
      const index = zoneMaterialVariants.findIndex((m) => m.variant.id === material.id);
      setCurrentColor(ChartColors[index]);
      setSelectedMaterial(material);
    },
    [zoneMaterialVariants],
  );

  const onDateClick = useCallback((value: DaysOption) => {
    setSelectedDays(value);
  }, []);

  useEffect(() => {
    void refetch();
  }, [selectedMaterial, selectedDays, refetch]);

  const onNewBaselineSet = useCallback(async () => {
    for await (const ms of [1000, 4000]) {
      await new Promise((resolve) => setTimeout(resolve, ms));
      await refetch();
    }
  }, [refetch]);

  return (
    <Container>
      <TitleContainer>
        <Title text={t('side-menu.zone.material-balance.header')} />
        <SetBaseline
          zoneId={zoneId}
          onCompleted={() => {
            void onNewBaselineSet();
          }}
        />
      </TitleContainer>
      <div>
        {zoneMaterialVariants.length ? (
          <MaterialSelector
            onMaterialChange={onMaterialChange}
            selectedMaterial={selectedMaterial}
            zoneMaterialVariants={zoneMaterialVariants}
          />
        ) : null}
        <div>
          {loading ? (
            <CenteredSpinner style={{ height: '300px' }} />
          ) : zoneMaterialVariants.length && selectedMaterial ? (
            <Chart
              min={minValue}
              max={maxValue}
              data={data}
              selectedMaterial={selectedMaterial}
              zoneMaterialVariants={zoneMaterialVariants}
              currentColor={currentColor}
              unit={unit}
            />
          ) : (
            <EmptyState />
          )}
        </div>
        <ButtonContainer>
          <Button
            variant="tertiary"
            iconEnd="chevron-down"
            ref={(ref) => {
              setRefDateElement(ref);
            }}
            onClick={() => setDaysOpen(true)}
          >
            {t('details-panel.date-filter', { days: selectedDays })}
          </Button>
          <ContextMenu
            style={{ fontSize: '14px' }}
            reference={refDateElement}
            referenceType={ReferenceType.Anchor}
            placement="bottom-start"
            open={daysOpen}
            onClose={() => setDaysOpen(false)}
          >
            {daysOptions.map((days) => (
              <ContextMenuItem
                style={{ fontSize: '14px' }}
                key={days}
                onClick={() => {
                  setDaysOpen(false);
                  onDateClick(days);
                }}
              >
                {t('details-panel.date-filter', { days })}
              </ContextMenuItem>
            ))}
          </ContextMenu>
        </ButtonContainer>
      </div>
    </Container>
  );
};
