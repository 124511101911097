import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusLight, Tooltip, Typography } from '@volvo/vce-uikit';
import { useMapContext } from '../../../../../context/map';
import { Button, ButtonWrapper } from './styles';

export const Button3D: FC = () => {
  const { t } = useTranslation();
  const { enabled3D, setEnabled3D, visibility, setVisibility } = useMapContext();

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (visibility.materialZones3D) {
      setVisibility({ materialZones3D: false });
    }
    setEnabled3D(!enabled3D);
  };

  return (
    <>
      <ButtonWrapper>
        <Button ref={(ref) => setReferenceElement(ref)} onClick={onClick}>
          <StatusLight status={enabled3D ? 'success' : 'neutral'} />
          <Typography variant="subtitle2">{t('3D')}</Typography>
        </Button>
      </ButtonWrapper>
      <Tooltip
        reference={referenceElement}
        open={open}
        offset={20}
        setOpen={setOpen}
        style={{ width: enabled3D ? '285px' : '110px' }}
        placement="right"
      >
        {t(!enabled3D ? '3d-mode-toggle' : '3d-mode-info')}
      </Tooltip>
    </>
  );
};
