import styled from '@emotion/styled';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon } from '@volvo/vce-uikit';
import type { ConnectedFlow } from '../../../../../context/connected-flows';
import { useNavigationContext } from '../../../../../context/navigation';
import { Link } from '../../../typography/Typography';
import { Text } from './styles';

const Line = styled.div`
  display: grid;
  grid-template-columns: 24px auto 1fr;
  justify-items: flex-start;
  padding-right: 24px;
`;

const Container = styled.div`
  display: grid;
  row-gap: 8px;
  margin-top: 4px;
`;

type ZoneFlowProps = {
  flow: ConnectedFlow;
  otherFeature: { name: string; type: 'to' | 'from' };
};

export const Item: FC<ZoneFlowProps> = ({ flow, otherFeature }) => {
  const { t } = useTranslation();
  const { baseUrl } = useNavigationContext();

  return (
    <Container>
      <Link to={`${baseUrl}/flow/${flow.flow.id}`} text={flow.flow.name} />
      <Line>
        <SystemIcon icon={`${otherFeature.type}-zone`} size={24} style={{ marginTop: '-6px' }} />
        <Text>{t(`side-menu.zone.${otherFeature.type}`)}:</Text>
        <Text>{otherFeature.name}</Text>
      </Line>
    </Container>
  );
};
