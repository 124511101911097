import styled from '@emotion/styled';

export const Row = styled.section<{ columns: number }>`
  display: grid;
  ${({ columns }) =>
    columns === 3
      ? 'grid-template-columns: 24px 24px 1fr'
      : columns === 2
        ? 'grid-template-columns: 24px 1fr'
        : 'grid-auto-flow: column'};
  align-items: center;
  column-gap: 4px;
  justify-content: start;
`;

export const List = styled.ul`
  max-height: 100px;
  overflow-y: auto;
  pointer-events: auto;
  margin-top: 2px;
`;

export const Container = styled.section`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  position: relative;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 12px;
  overflow: hidden;
  background: #fff;
`;
