import { type FC, type PropsWithChildren } from 'react';
import { Body, Title } from '../../../../typography/Typography';
import { Container } from './styles';

interface Props extends PropsWithChildren {
  title?: string;
  content?: string;
}

export const Row: FC<Props> = ({ children, content, title = '' }) => (
  <Container>
    <Title text={title} />
    {content && <Body text={content} />}
    {children}
  </Container>
);
