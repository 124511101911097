import { type FC } from 'react';
import { useMapContext } from '../../../../context/map';
import { useNavigationContext } from '../../../../context/navigation';
import { Button3D } from './button/Button';
import { Container } from './styles';

export const ThreeDMode: FC = () => {
  const { mapEdit } = useMapContext();
  const { leftPanelOpen } = useNavigationContext();

  if (mapEdit) {
    return null;
  }

  return (
    <Container leftPanelOpen={leftPanelOpen}>
      <Button3D />
    </Container>
  );
};
