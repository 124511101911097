import type { FC } from 'react';
import { SystemIcon } from '@volvo/vce-uikit';
import { theme } from '../../../theme';
import { Dot } from './styles';

type Direction = 'row' | 'column';

type Props = {
  items: { color: string; label: string; description?: string }[];
  direction?: Direction;
  dotSize?: number;
};

export const Legend: FC<Props> = ({ items, direction, dotSize }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: direction ?? 'column',
      gap: direction === 'row' ? '32px' : undefined,
    }}
  >
    {items.map(({ color, label, description }, index: number) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
        <Dot color={color} size={dotSize} />
        {label}
        {description && (
          <span title={description}>
            <SystemIcon icon="info" size={16} style={{ marginLeft: theme.map.layout.margin }} />
          </span>
        )}
      </div>
    ))}
  </div>
);
