import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  row-gap: ${(props) => props.theme.map.layout.padding};
  column-gap: 24px;
  padding: ${(props) => props.theme.map.layout.verticalPadding};
`;

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;
