import { memo, type FC } from 'react';
import type { GuestDevice } from '../../../../context/machine-locations';
import type { MapMachine } from '../../../../types';
import { Filter } from './filter/Filter';
import { GuestsList } from './list/guests-list/GuestsList';
import { MachinesList } from './list/machines-list/MachinesList';
import { SearchContainer } from './styles';

type Props = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  machineSearchResults: MapMachine[];
  guestSearchResults: GuestDevice[];
};

const MachinesContentComponent: FC<Props> = ({
  searchTerm,
  setSearchTerm,
  machineSearchResults,
  guestSearchResults,
}) => (
  <>
    <SearchContainer>
      <Filter searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </SearchContainer>
    <ul>
      <GuestsList searchResults={guestSearchResults} />
      <MachinesList searchResults={machineSearchResults} />
    </ul>
  </>
);

export const MachinesContent = memo(MachinesContentComponent);
