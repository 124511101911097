import { t } from 'i18next';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react';
import { ElementType } from '@volvo/vce-package-site-mapcommon';
import { toast } from '@volvo/vce-uikit';
import {
  FragmentZonePermissionType,
  useQueryMachineTypePermissionsLazyQuery,
  useQueryZonePermissionTypes,
  type ZonePermissionTypeModel,
} from '../../gql-types/generated-types-super-graph';
import { machineTypeZoneRoles, type MachineTypeZoneRole } from '../../types';
import { useNavigationContext } from '../navigation';
import { useSiteContext } from '../site';
import { type MachineType, type ZoneContextType, type ZoneTab } from './types';

const ZoneContext = createContext({} as ZoneContextType);

export const ZoneProvider: FC<PropsWithChildren> = ({ children }) => {
  const { site } = useSiteContext();
  const { selected } = useNavigationContext();
  const [selectedTab, setSelectedTab] = useState<ZoneTab>('GENERAL');

  const [drivingPermissionId, setDrivingPermissionId] = useState('');
  const [zonePermissionTypes, setZonePermissionTypes] = useState<FragmentZonePermissionType[]>([]);
  const [zonePermissionTypesAll, setZonePermissionTypesAll] = useState<
    FragmentZonePermissionType[]
  >([]);
  const [roleAssignments, setRoleAssignments] = useState<ZoneContextType['roleAssignments']>({});
  const [initialRoleAssignments, setInitialRoleAssignments] = useState<
    ZoneContextType['initialRoleAssignments']
  >({});
  const [machineTypePermissions, setMachineTypePermissions] = useState<
    Record<string, FragmentZonePermissionType[]>
  >({});

  if (selected?.type !== 'feature' || !selected.feature) {
    throw new Error('Invalid feature type');
  }

  const [getMachineTypePermissionsQuery] = useQueryMachineTypePermissionsLazyQuery();

  const { refetch } = useQueryZonePermissionTypes({
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      const zonePermissionTypes = data.zonePermissionTypes.all;
      const drivingPermission = zonePermissionTypes.find((zpt) => zpt.type === 'DRIVING');
      const loadingPermission = zonePermissionTypes.find((zpt) => zpt.type === 'LOADING');
      const unloadingPermission = zonePermissionTypes.find((zpt) => zpt.type === 'OFF_LOADING');
      const parkingPermission = zonePermissionTypes.find((zpt) => zpt.type === 'PARKING');
      if (!drivingPermission || !loadingPermission || !unloadingPermission || !parkingPermission) {
        toast.error(
          `${t('errors.zone-role-editor-error-title')}\n\n${t(
            'errors.zone-role-editor-error-description',
          )}`,
        );
        return;
      }

      setDrivingPermissionId(drivingPermission.id);

      setZonePermissionTypesAll(zonePermissionTypes);

      setZonePermissionTypes([
        loadingPermission,
        unloadingPermission,
        parkingPermission,
        { id: 'restricted', type: 'RESTRICTED', description: '' },
      ]);

      const zonePermissionTypeMap: Record<MachineTypeZoneRole, FragmentZonePermissionType[]> = {
        drivingOnly: [drivingPermission],
        loading: [loadingPermission],
        unloading: [unloadingPermission],
        loadingAndUnloading: [loadingPermission, unloadingPermission],
        parking: [parkingPermission],
        restricted: [],
      };

      const defaultPermissions: Record<string, MachineType[]> = {
        drivingOnly: [...site.allowedMachineTypes],
        loading: [],
        unloading: [],
        loadingAndUnloading: [],
        parking: [],
        restricted: [],
      };

      const machineTypePermissions =
        !selected.isNew &&
        selected.feature.properties.element_type === ElementType.ZONE &&
        selected.feature.id
          ? await getMachineTypePermissionsQuery({
              variables: { zoneId: selected.feature.id.toString() },
            })
          : undefined;

      // 1 Flatten permissions for each machine type
      const machineTypeToPermissionsMap: Record<string, FragmentZonePermissionType[]> = {};
      if (machineTypePermissions?.data) {
        machineTypePermissions.data.machineTypePermissions.byZoneId.forEach((mtp) => {
          const machineType = site.allowedMachineTypes.find(({ id }) => id === mtp.machineTypeId);
          if (!machineType) {
            return;
          }
          machineTypeToPermissionsMap[machineType.id] = [
            ...(machineTypeToPermissionsMap[machineType.id] || []),
            mtp.zonePermissionType,
          ];
        });
      } else {
        site.allowedMachineTypes.forEach(({ id }) => {
          machineTypeToPermissionsMap[id] = [drivingPermission];
        });
      }

      setMachineTypePermissions({ ...machineTypeToPermissionsMap });

      // 1b Restricted (no permissions)
      const machineTypesWithoutPermissions = site.allowedMachineTypes.filter(
        ({ id }) =>
          machineTypePermissions?.data &&
          !machineTypePermissions.data.machineTypePermissions.byZoneId
            .map(({ machineTypeId }) => machineTypeId)
            .includes(id),
      );

      machineTypesWithoutPermissions.forEach(({ id }) => {
        machineTypeToPermissionsMap[id] = [];
      });

      // 2 Map ZonePermissionTypes to MachineTypeZoneRole by using zonePermissionTypeMap in reverse
      const rolesForMachineTypes: Record<string, MachineType[]> = {};

      Object.entries(machineTypeToPermissionsMap).forEach(([machineTypeId, permissions]) => {
        const machineType = site.allowedMachineTypes.find(({ id }) => id === machineTypeId);
        if (!machineType) {
          return;
        }

        if (permissions.length === 1 && permissions[0].type === 'DRIVING') {
          rolesForMachineTypes.drivingOnly = [
            ...(rolesForMachineTypes.drivingOnly || []),
            machineType,
          ];
        } else if (permissions.length === 0) {
          rolesForMachineTypes.restricted = [
            ...(rolesForMachineTypes.restricted || []),
            machineType,
          ];
        } else {
          const permissionTypes = Object.entries(zonePermissionTypeMap);
          permissionTypes.forEach(([zoneRoleType, zonePermissionTypes]) => {
            let loadingAndUnloading = false;
            if (
              zoneRoleType === 'loadingAndUnloading' &&
              permissions.some((p) => p.id === loadingPermission.id) &&
              permissions.some((p) => p.id === unloadingPermission.id) &&
              !loadingAndUnloading
            ) {
              rolesForMachineTypes.loadingAndUnloading = [
                ...(rolesForMachineTypes.loadingAndUnloading || []),
                machineType,
              ];
              loadingAndUnloading = true;
            }

            if (
              zoneRoleType !== 'restricted' &&
              zoneRoleType !== 'loadingAndUnloading' &&
              zoneRoleType !== 'drivingOnly' &&
              zonePermissionTypes.every((zpt) => permissions.map((p) => p.id).includes(zpt.id))
            ) {
              rolesForMachineTypes[zoneRoleType] = [
                ...(rolesForMachineTypes[zoneRoleType] || []),
                machineType,
              ];
            }

            if (loadingAndUnloading) {
              rolesForMachineTypes.loading = rolesForMachineTypes.loading?.filter(
                (mt) => mt.id !== machineType.id,
              );
              rolesForMachineTypes.unloading = rolesForMachineTypes.unloading?.filter(
                (mt) => mt.id !== machineType.id,
              );
            }
          });
        }
      });

      // 3 set state
      const initialRoleAssignments: Record<string, MachineType[]> = machineTypeZoneRoles.reduce(
        (acc, mtzr) => ({
          ...acc,
          [mtzr]: rolesForMachineTypes[mtzr] ?? [],
        }),
        {},
      );

      if (selected.isNew) {
        const defaultRoleAssignments: Record<string, ZonePermissionTypeModel[]> = {};

        site.allowedMachineTypes.forEach((machineType) => {
          defaultRoleAssignments[machineType.id] = [drivingPermission];
        });

        setRoleAssignments(defaultPermissions);
        setInitialRoleAssignments(defaultRoleAssignments);
      } else {
        setRoleAssignments(initialRoleAssignments);
        setInitialRoleAssignments(machineTypeToPermissionsMap);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, selected.feature.id]);

  return (
    <ZoneContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        initialRoleAssignments,
        drivingPermissionId,
        zonePermissionTypesAll,
        zonePermissionTypes,
        roleAssignments,
        machineTypePermissions,
        setMachineTypePermissions,
      }}
    >
      {children}
    </ZoneContext.Provider>
  );
};

export const useZoneContext = () => useContext(ZoneContext);
