import { useCallback, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../../../../../../context/navigation';
import type { MaterialVariantItem } from '../../../../../../../../context/zone-edit/types';
import { useZoneEditContext } from '../../../../../../../../context/zone-edit/ZoneEditContext';
import { useZonePropertiesEditContext } from '../../../../../../../../context/zone-properties-edit/ZonePropertiesEditContext';
import { SearchAndSelect } from '../../../../../../../common/search-and-select/SearchAndSelect';
import { VerticalSpacing } from '../../../../../../../common/spacing/VerticalSpacing';
import { MaterialVariant } from './styles';

export const MaterialSelector: FC = () => {
  const { t } = useTranslation();
  const { setDirty } = useNavigationContext();
  const { materialsTabState, setMaterialsTabState, materials } = useZoneEditContext();
  const { setMaterialVariantsAccordionOpen } = useZonePropertiesEditContext();

  const [searchResults, setSearchResults] = useState<MaterialVariantItem[]>();

  const addMaterialVariant = useCallback(
    (item: MaterialVariantItem) => {
      if (materialsTabState?.find(({ id }) => item.id === id)) {
        return;
      }

      setDirty(true);
      setMaterialsTabState((prev) => [...prev, item]);
      setMaterialVariantsAccordionOpen(true);
    },
    [materialsTabState, setDirty, setMaterialsTabState, setMaterialVariantsAccordionOpen],
  );

  const handleChange = useCallback(
    (val: string) => {
      const valsLowerCase = val.toLowerCase().split(' ');
      const filteredVariants = materials.filter(
        (i) =>
          valsLowerCase.every((v) => i.name.toLowerCase().includes(v)) &&
          !materialsTabState?.find(({ id }) => i.id === id) &&
          !i.isDeleted,
      );

      setSearchResults(filteredVariants);
    },
    [materials, materialsTabState],
  );

  return (
    <>
      <Typography variant="subtitle2" color="textSubtlest">
        {t('details-panel.zone.add-material')}
      </Typography>
      <VerticalSpacing spacing={5} />
      <SearchAndSelect<MaterialVariantItem>
        items={searchResults ?? []}
        onChange={handleChange}
        onSelect={(item) => {
          addMaterialVariant(item);
        }}
        renderItem={(variant) => <MaterialVariant>{variant.name}</MaterialVariant>}
      />
    </>
  );
};
