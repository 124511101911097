import styled from '@emotion/styled';

export const Button = styled.button`
  border: none;
  background: transparent;
  padding: 7px;
`;

export const ButtonWrapper = styled.div`
  border-radius: 5px;
  background: #fff;
`;

export const CollapsibleDiv = styled.div`
  transition: height 0.2s ease-in-out;
`;
