import styled from '@emotion/styled';
import { Popup } from 'react-map-gl';
import { theme } from '../../../../../../theme';

export const CustomPopup = styled(Popup)`
  z-index: 6;
  .mapboxgl-popup-content {
    border-radius: 4px;
    border: 1px solid ${theme.colors.borderSelected};
    padding: 12px;
    overflow: hidden;
  }
  .mapboxgl-popup-tip {
    border: none;
  }
`;
