import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const Container = styled.div<{ open: boolean }>`
  position: absolute;
  left: ${(props) => (props.open ? 0 : '350px')};
  top: calc(50% - 24px);
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid ${theme.colors.borderSubtle};
  border-radius: 0 8px 8px 0;
  background: ${theme.colors.bg} 7px center/7px 10px no-repeat;
`;
