import type { FC } from 'react';

export const NarrowLane: FC = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m13.7318 3c-.7698-1.33333-2.6943-1.33333-3.4641 0l-8.66028 15c-.769798 1.3333.19245 3 1.73205 3h17.32053c1.5396 0 2.5018-1.6667 1.732-3z"
      fill="#ffb54d"
      stroke="#bf2012"
      strokeWidth="2"
    />
    <g stroke="#141414" strokeWidth="1.5">
      <path d="m10.5 9v3.3221c0 .479-.1719.9421-.4845 1.305l-.03104.0361c-.31255.363-.48446.8261-.48446 1.3051v3.0317" />
      <path d="m13.5 9v3.3221c0 .479.1719.9421.4845 1.305l.031.0361c.3126.363.4845.8261.4845 1.3051v3.0317" />
    </g>
  </svg>
);
