import styled from '@emotion/styled';

export const RibbonOuter = styled.div`
  position: absolute;
  display: grid;
  width: 100%;
  z-index: 4;
  background-color: rgba(170, 170, 170, 0.8);
`;

export const RibbonInner = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 8px;
`;
