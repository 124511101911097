import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type PointLike } from 'react-map-gl';
import { StatusLight, SystemIcon, Typography } from '@volvo/vce-uikit';
import { useMapContext } from '../../../../../context/map';
import { getMachineAppearance } from '../../../../../helpers';
import { theme } from '../../../../../theme';
import { type HoverEvent } from '../machine-marker';
import { CustomPopup } from './custom-popup/CustomPopup';
import { Row } from './style';

type Props = { popup: HoverEvent };

export const MachinePopup: FC<Props> = ({ popup }) => {
  const { t } = useTranslation();
  const { mapEdit } = useMapContext();

  if (mapEdit) {
    return null;
  }

  const offset: PointLike = [0, -44];

  return (
    <CustomPopup
      longitude={popup.lngLat.lng}
      latitude={popup.lngLat.lat}
      closeButton={false}
      offset={offset}
      anchor="bottom"
    >
      <Row>
        <SystemIcon size={24} icon={getMachineAppearance(popup.machineType).icon} />
        <Typography variant="subtitle2">{popup.name}</Typography>
      </Row>
      <Row>
        {popup.onlineStatus === 'ACTIVE' ? (
          <section>
            <StatusLight status="success">{t('active')}</StatusLight>
          </section>
        ) : popup.onlineStatus === 'LOST_CONNECTION' ? (
          <>
            <SystemIcon size={16} icon="warning" style={{ color: theme.colors.textError }} filled />
            <Typography>{t('lost-connection')}</Typography>
          </>
        ) : (
          <>
            <SystemIcon
              size={16}
              icon="wireless-communication-off"
              style={{ color: theme.colors.bgHandleDisabled }}
            />
            <Typography>{t('offline')}</Typography>
          </>
        )}
      </Row>
    </CustomPopup>
  );
};
