import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon } from '@volvo/vce-uikit';
import { theme } from '../../../../../../../theme';

export const Error: FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center', color: theme.colors.textError }}>
      <SystemIcon
        icon="warning"
        size={24}
        style={{ color: theme.colors.textError, marginRight: '5px' }}
      />
      {t('faulty-unloads.errors.undefined-materials')}
    </div>
  );
};
