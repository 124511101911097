import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CenterContainer = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: ${(props) => (props.padding ? props.padding : '0')};
`;

export const ColumnCellContainer = styled.div`
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  gap: ${theme.map.layout.gutter.small};
  height: 40px;
`;

export const FilterStylingContainer = styled.div<{ showFilter: boolean }>`
  display: ${(props) => (props.showFilter ? 'flex' : 'none')};
  width: 100%;
  flex-direction: row;
  align-items: center;

  gap: ${theme.map.layout.gutter.small};
`;

export const FilterIconDiv = styled.div`
  align-self: flex-start;
  align-items: center;
  display: flex;
`;

export const InfoBox = styled.div<{ marginTop?: string }>`
  background-color: ${(props) => props.theme.colors.bgCategory2Subtle};
  border-radius: ${(props) => props.theme.radius.small};
  padding: 20px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : null)};
  display: table;
`;
