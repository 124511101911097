import styled from '@emotion/styled';
import { type CSSProperties } from 'react';

export const MapContainer = styled.div`
  position: relative;
  flex: 1;
  content: 'martin';
`;

export const mapStyles = (height: string): CSSProperties => {
  return {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height,
    zIndex: 1,
  };
};
