import styled from '@emotion/styled';
import { theme } from '../../../../../theme';

export const Container = styled.div`
  backgroundcolor: ${theme.colors.bgDisabled};
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

export const ButtonContainer = styled.div`
  float: right;
  justify-content: end;
  margin-top: 20px;
`;
