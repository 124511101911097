import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@volvo/vce-uikit';
import { useNavigationContext } from '../../../context/navigation';
import { theme } from '../../../theme';
import { Container } from './styles';

export const MobileButton: FC = () => {
  const { leftPanelOpen, setLeftPanelOpen, isMobile, isTabletPortrait, selected } =
    useNavigationContext();
  const { t } = useTranslation();

  if ((!isMobile && !isTabletPortrait) || leftPanelOpen || selected) return null;

  return (
    <Container>
      <Button
        onClick={() => {
          setLeftPanelOpen(true);
        }}
        variant="secondary"
        style={{ zIndex: 2, backgroundColor: theme.colors.bg, marginBottom: '20px' }}
      >
        {t('side-menu.mobile-button')}
      </Button>
    </Container>
  );
};
