import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';
import { Title } from '../../../typography/Typography';

interface Props extends PropsWithChildren {
  title?: string;
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto;
  margin-bottom: 8px;
`;

export const Container: FC<Props> = ({ children, title = '' }) => (
  <Grid>
    <Title text={title} style={{ marginBottom: '16px' }} />
    {children}
  </Grid>
);
