import styled from '@emotion/styled';
import { theme } from '../../../../../theme';

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

export const ListItem = styled.li<{ disabled?: boolean; selected?: boolean }>`
  display: grid;
  grid-template-columns: 48px 1fr 16px;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  min-height: 64px;
  border-bottom: 1px solid ${theme.colors.borderSubtle};
  background-color: ${(props) => (props.selected ? theme.colors.bgPressed : 'transparent')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${theme.colors.bgHover};
  }
  &:active {
    background-color: ${theme.colors.bgPressed};
  }
`;
